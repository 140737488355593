<template>
  <section class="hero is-fullheight">
    <ModalHeaderInformatioToOrder
      v-if="activeModalHeaderInformatioToOrder"
      :active="activeModalHeaderInformatioToOrder"
      @close-modal="closeModalHeaderInformatioToOrder"
      @set-data-add-header-table="addHeaderTable"
    />
    <ModalHeaderInformatioToOrder
      v-if="activeModalEditHeaderInformatioToOrder"
      :active="activeModalEditHeaderInformatioToOrder"
      :idTable="tableIdCurrentUpdte"
      :dataTableParent="dataCurrentHeaderUpdate"
      @close-modal="closeModalHeaderInformatioToOrder"
      @set-data-add-header-table="updateHeaderTable"
    />
    <ModalRowInformationToOrder
      v-if="activeModalRowInformationToOrder"
      :active="activeModalRowInformationToOrder"
      :idTable="currentRowIdTable"
      :enabledRowHeaders="enabledRowHeaders"
      :currencyList="currencyList"
      @close-modal="closeModalRowInformationToOrder"
      @set-data-add-row-table="addRowTable"
    />
    <ModalRowInformationToOrder
      v-if="activeModalEditRowInformationToOrder"
      :modeEdit="true"
      :active="activeModalEditRowInformationToOrder"
      :idTable="currentRowIdTable"
      :indexRowTable="indexRowTable"
      :enabledRowHeaders="enabledRowHeaders"
      :currencyList="currencyList"
      :rowHeadersCurrent="rowHeadersCurrent"
      @close-modal="closeModalRowInformationToOrder"
      @set-data-add-row-table="updateRowTable"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline">
                  <div class="column is-10">
                    <div class="columns is-multiline">
                      <div class="column is-3">
                        <label class="label">Nombre del producto</label>
                        <template v-if="modeEdit">
                          <input
                            class="input"
                            type="text"
                            placeholder="Nombre del producto"
                            v-model="productName"
                            name="productName"
                            v-validate="'required'"
                            data-vv-as="del nombre del producto"
                          >
                          <span v-show="vverrors.has('productName')" class="help is-danger">{{ vverrors.first('productName') }}</span>
                        </template>
                        <template v-else>
                          <p class="paragraph">{{ productName }}</p>
                        </template>
                      </div>
                      <div class="column is-3">
                        <label class="label">Marca</label>
                        <template v-if="modeEdit">
                          <div class="select slct is-fullwidth">
                            <select v-model="brandId" name="brandId" v-validate="'required'" data-vv-as="de la marca">
                              <option selected disabled :value="null">Marca</option>
                              <option v-for="brand in brandList" :key="brand.id" :value="brand.id">
                                {{ brand.name }}
                              </option>
                            </select>
                          </div>
                          <span v-show="vverrors.has('brandId')" class="help is-danger">{{ vverrors.first('brandId') }}</span>
                        </template>
                        <template v-else>
                          <p class="paragraph">{{ brandName }}</p>
                        </template>
                      </div>
                      <div class="column is-2">
                        <label class="label">Estatus</label>
                        <template v-if="modeEdit">
                          <span>
                            <input
                              id="switchStatus"
                              type="checkbox"
                              class="switch is-rounded is-small"
                              :checked="status ? 'checked' : ''"
                              v-model="status"
                              name="switchStatusStatus"
                            >
                            <label for="switchStatus"></label>
                          </span>
                        </template>
                        <template v-else>
                          <p class="paragraph" :class="[ status ? 'text_green' : 'text_error' ]">
                            {{ status ? 'Activo' : 'Inactivo' }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="column is-5">
                    <label class="label">Introducción</label>
                    <template v-if="modeEdit">
                      <vue-editor
                        :editor-toolbar="customToolbar"
                        placeholder="Introducción"
                        v-model="introduction"
                        name="introduction"
                        @input="handleMaxInputLimit(150)"
                        data-vv-as="de la introducción"
                      />
                      <span v-show="characterCount > 150" class="help is-danger">El campo de la introducción no debe ser mayor a 150 caracteres</span>
                      <span v-show="vverrors.has('introduction')" class="help is-danger">{{ vverrors.first('introduction') }}</span>
                    </template>
                    <template v-else>
                      <p class="paragraph" v-html="introduction" />
                    </template>
                  </div>
                  <div class="column is-5">
                    <label class="label">Descripción</label>
                    <template v-if="modeEdit">
                      <vue-editor
                        :editor-toolbar="customToolbar"
                        v-model="description"
                        placeholder="Descripción"
                        name="description"
                        v-validate="'required'"
                        data-vv-as="de la descripción"
                      />
                      <span v-show="vverrors.has('description')" class="help is-danger">{{ vverrors.first('description') }}</span>
                    </template>
                    <template v-else>
                      <p class="paragraph" v-html="description" />
                    </template>
                  </div>
                  <div class="column is-10">
                    <div class="columns is-multiline">
                      <div class="column is-3">
                        <card-upload-file
                          typeFile="pdf"
                          class="card_upload"
                          :whitBorder="true"
                          nameInputFile="imageDataSheet"
                          titleCard="Ficha técnica"
                          :previewImage="previewImageDataSheet"
                          :setFile="setFileDataSheet"
                          :modeEdit="modeEdit"
                          :filename="filename"
                          validate="ext:pdf"
                          accept=".pdf"
                        />
                      </div>
                      <div class="column is-3">
                        <label class="label">Tiempo de entrega</label>
                        <template v-if="modeEdit">
                          <input
                            class="input"
                            type="text"
                            placeholder="Tiempo de entrega"
                            v-model="deliveryTime"
                            name="deliveryTime"
                            v-validate="'required'"
                            data-vv-as="del tiempo de entrega"
                          >
                          <span v-show="vverrors.has('deliveryTime')" class="help is-danger">{{ vverrors.first('deliveryTime') }}</span>
                        </template>
                        <template v-else>
                          <p class="paragraph">{{ deliveryTime }}</p>
                        </template>
                      </div>
                      <div class="column is-3">
                        <label class="label">IVA</label>
                        <p class="paragraph">
                          {{ fixed_VAT }}%
                        </p>
                      </div>
                      <div class="column is-3">
                        <label class="label">Disponible sólo para México</label>
                        <template v-if="modeEdit">
                          <span>
                            <input
                              id="switchAvailableOnlyMexico"
                              type="checkbox"
                              class="switch is-rounded is-small"
                              :checked="availableOnlyMexico ? 'checked' : ''"
                              v-model="availableOnlyMexico"
                              name="switchAvailableOnlyMexico"
                            >
                            <label for="switchAvailableOnlyMexico"></label>
                          </span>
                        </template>
                        <template v-else>
                          <p class="paragraph">
                            {{ availableOnlyMexico ? 'Si' : 'No' }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="columns is-multiline">
                      <div class="column is-4">
                        <label class="label">Mostrar botón "Añadir al carrito"</label>
                        <template v-if="modeEdit">
                          <span>
                            <input
                              id="switchEnableAddCart"
                              name="switchEnableAddCart"
                              type="checkbox"
                              class="switch is-rounded is-small"
                              :checked="switchEnableAddCart ? 'checked' : ''"
                              v-model="switchEnableAddCart"
                            >
                            <label for="switchEnableAddCart"></label>
                          </span>
                        </template>
                        <template v-else>
                          <p class="paragraph">
                            {{ switchEnableAddCart ? 'Si' : 'No' }}
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- Líneas -->
                  <template v-if="modeEdit">
                    <div class="column is-6">
                      <div class="columns is-multiline is-mobile">
                        <div class="column is-8-desktop is-9-tablet is-9-mobile">
                          <div class="select slct is-fullwidth">
                            <select v-model="lineId" name="lineId">
                              <option selected disabled :value="null">Línea</option>
                              <option v-for="line in lineList" :key="line.id" :value="line.id">
                                {{ line.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="column is-2-desktop is-3-touch is-3-mobile">
                          <button class="button is_green" @click="addSubLineSelected">
                            +
                          </button>
                        </div>
                        <div class="column is-12" v-if="errorNoLineSelected">
                          <span class="help is-danger">
                            Debe seleccionar al menos una Línea
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- Opciones de Líneas -->
                    <template v-if="optionsLines.length > isEmpty && !loadingCheckBox">
                      <template v-for="(optionLine, indexLine) in optionsLines">
                        <div class="column is-12" :key="optionLine.id" v-if="optionLine.isSelect">
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <div class="columns is-multiline is-mobile is-vcentered">
                                <div class="content-width">
                                  <p class="paragraph has-text-weight-bold">
                                    {{ optionLine.name }}
                                  </p>
                                </div>
                                <div class="column is-1 has-text-centered">
                                  <span class="material-icons text_error delete_icon" @click="deleteLine(indexLine); checkLines();">
                                    delete
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="column is-12" v-if="!showSublines"></div>
                            <div class="column is-12" v-if="showSublines">
                              <label class="label">Sub línea</label>
                              <p class="paragraph mb-5" :class="{ 'mb-1' : errorNoSubLineSelected }">
                                Elige las sub líneas a las que pertenece este producto
                              </p>
                              <span class="help is-danger" v-if="errorNoSubLineSelected">
                                Debe seleccionar al menos una Sub Línea por cada Línea agregada
                              </span>
                              <span class="help is-danger" v-if="errorNoChildSubLineSelected">
                                Debe seleccionar una Sub Línea de producto por cada Sub Línea seleccionada
                              </span>
                            </div>
                            <!-- Sub línea -->
                            <div class="column is-12" v-for="(subline, indexSubline) in optionLine.subline" :key="subline.id">
                              <div class="columns is-multiline">
                                <div class="column is-12">
                                  <label class="checkbox has-text-weight-bold">
                                    <input
                                      :id="`subline_${indexLine}_${indexSubline}`"
                                      type="checkbox"
                                      :checked="optionsLines[indexLine].subline[indexSubline].isSelect ? 'checked' : ''"
                                      @change="changeCheckboxSubLine(indexLine, indexSubline)"
                                    >
                                    {{ subline.name }}
                                  </label>
                                </div>
                                <div class="column is-11 is-offset-1" v-if="subline.isSelect && subline.childLine.some(child => child.id != null)">
                                  <div class="columns is-multiline">
                                    <div class="column is-3" v-for="(childSubline, indexChild) in subline.childLine" :key="childSubline.id">
                                      <div class="control">
                                        <label class="checkbox has-text-weight-bold">
                                          <input
                                            type="checkbox"
                                            :name="`child_${indexLine}_${indexSubline}`"
                                            :checked="optionsLines[indexLine].subline[indexSubline].childLine[indexChild].isSelect ? 'checked' : ''"
                                            @change="setValueChildLine(indexLine, indexSubline, indexChild)"
                                          >
                                          {{ childSubline.name }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div class="column is-12" v-for="myLines in optionsLines" :key="`myLines_${myLines.id}`">
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <label class="label">Línea</label>
                          <p class="paragraph">{{ myLines.name }}</p>
                        </div>
                        <div class="column is-12">
                          <label class="label">Sub líneas</label>
                        </div>
                        <div class="column is-3 is-flex is-flex-direction-row" v-for="mysublines in myLines.subline" :key="`myLines_${mysublines.id}`">
                          <template v-if="mysublines.isSelect">
                            <span class="material-icons text_green">
                              check
                            </span>
                            <div class="ml-3">
                              <p class="paragraph has-text-weight-semibold">
                                {{ mysublines.name }}
                              </p>
                              <template v-for="mychildLine in mysublines.childLine">
                                <p class="paragraph" :key="`mychildLine${mychildLine.id}`">
                                  <template v-if="mychildLine.isSelect">
                                    ({{ mychildLine.name }})
                                  </template>
                                </p>
                              </template>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- Sectores y Palabras clave -->
                  <div class="column is-6">
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-12">
                        <label class="label">Sector</label>
                      </div>
                      <template v-if="modeEdit">
                        <div class="column is-8-desktop is-9-tablet is-9-mobile">
                          <div class="select slct is-fullwidth">
                            <select v-model="sectorIdSelect" name="sectorIdSelect" :class="{ 'is-loading' : loadingCatalog }">
                              <option selected disabled :value="null">Sector</option>
                              <option v-for="sector in sectorList" :key="sector.id" :value="sector.id">
                                {{ sector.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="column is-2-desktop is-3-touch is-3-mobile">
                          <button class="button is_green" @click="addSector">
                            +
                          </button>
                        </div>
                        <div class="column is-12" v-if="sectorListItsEmpty">
                          <span class="help is-danger">
                            Debe agregar al menos un Sector
                          </span>
                        </div>
                      </template>
                      <div class="column is-12">
                        <div class="tags">
                          <template v-for="(sector, index) in sectorListSelect">
                            <span class="tag is-medium is-rounded" :key="sector.id" v-if="sector.active">
                              {{ sector.name }}
                              <button v-if="modeEdit" class="delete" @click="removeSector(index, sector.id)"></button>
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-12">
                        <label class="label">Palabras clave</label>
                      </div>
                      <template v-if="modeEdit">
                        <div class="column is-12">
                          <textarea class="textarea" placeholder="Escribe aquí" name="keywordsList" id="keywordsList" v-model="keywordsList" v-validate="'required'" data-vv-as="debe contener al menos una palabra clave" />
                          <span v-show="vverrors.has('keywordsList')" class="help is-danger">{{ vverrors.first('keywordsList') }}</span>
                        </div>
                      </template>
                      <template v-else>
                        <div v-for="(keyword, id) in splitKeywords" v-bind:key="id" v-bind:keyword="keyword">
                          <span class="tag is-medium is-rounded" >
                            {{ keyword }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- Imagenes -->
                  <div class="column is-3">
                    <card-upload-file
                      class="card_upload"
                      :whitBorder="true"
                      nameInputFile="imageMain"
                      titleCard="Imagen principal"
                      :previewImage="previewImageMain"
                      :setFile="setFileMain"
                      :modeEdit="modeEdit"
                      validate="ext:jpeg,jpg,png|size:1000"
                      accept=".jpeg,.jpg,.png,"
                    />
                  </div>
                  <div class="column is-3">
                    <card-upload-file
                      class="card_upload"
                      :whitBorder="true"
                      nameInputFile="imageExtraOne"
                      titleCard="Imagen"
                      :previewImage="previewImageExtraOne"
                      :setFile="setFileExtraOne"
                      :modeEdit="modeEdit"
                      validate="ext:jpeg,jpg,png|size:1000"
                      accept=".jpeg,.jpg,.png,"
                    />
                  </div>
                  <div class="column is-3">
                    <card-upload-file
                      class="card_upload"
                      :whitBorder="true"
                      nameInputFile="imageExtraTwo"
                      titleCard="Imagen"
                      :previewImage="previewImageExtraTwo"
                      :setFile="setFileExtraTwo"
                      :modeEdit="modeEdit"
                      validate="ext:jpeg,jpg,png|size:1000"
                      accept=".jpeg,.jpg,.png,"
                    />
                  </div>
                  <div class="column is-12">
                    <!-- PRODUCTOS RELACIONADOS -->
                    <card-related-products
                      ref="cardAddRelatedProductUpRef"
                      :showCatalog="true"
                      :relatedProducts="productDetail.relatedProducts"
                      :guidesProductOptions="productsListSimplified"
                      :modeEdit="modeEdit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <div class="columns is-multiline">
                  <div class="column is-6">
                    <label class="label">Especificaciones</label>
                    <template v-if="modeEdit">
                      <textarea
                        class="textarea"
                        v-model="moreSpecs"
                        placeholder="Especificaciones"
                        name="moreSpecs"
                        v-validate="'required'"
                        data-vv-as="de especificaciones"
                      />
                      <span v-show="vverrors.has('moreSpecs')" class="help is-danger">{{ vverrors.first('moreSpecs') }}</span>
                    </template>
                    <template v-else>
                      <p class="paragraph">{{ moreSpecs }}</p>
                    </template>
                  </div>
                  <div class="column is-12">
                    <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                      <label class="label my-0 mr-3">Solicitar Demo</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchDemo"
                            type="checkbox"
                            class="switch is-rounded is-small"
                            :checked="productDemo ? 'checked' : ''"
                            v-model="productDemo"
                            name="switchDemo"
                          >
                          <label for="switchDemo"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="paragraph" :class="[ productDemo ? 'text_green' : 'text_error' ]">
                          {{ productDemo ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                    <p class="mt-2">
                      Al activar "Solicitar Demo" se mostrará el botón de solicitar Demo en el detalle del producto en la web.
                    </p>
                  </div>
                  <template >
                    <div class="column is-12">
                      <label class="label">Información para ordenar</label>
                    </div>
                    <div class="column is-12">
                      <div class="buttons">
                        <div class="file" :class="{ 'is-boxed' : modeEdit }">
                          <label class="file-label">
                            <div v-if="nameProductVariation" class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                              <img style="width: 15px; margin: 0 5px;" src="@/assets/icon/pdf-file-icon.png" alt="PDF">
                              <span>
                                {{ nameProductVariation }}
                              </span>
                            </div>
                            <template v-if="modeEdit">
                              <input
                                class="file-input"
                                type="file"
                                name="productVariation"
                                @change="setFileProductVariation"
                                validate="ext:pdf"
                                accept=".pdf"
                              >
                              <span class="file-cta">
                                <span class="file-label emphasis_link">
                                  {{ nameProductVariation ? 'Actualizar archivo' : 'Subir archivo' }}
                                </span>
                              </span>
                            </template>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <span class="help is-danger" v-if="emptyTables">
                        Al Activar las variables para este producto debe de haber una tabla y/o Archivo PDF
                      </span>
                      <span class="help is-danger" v-if="emptyTablesContent">
                        La(s) tabla(s) esta(n) vacia(s)
                      </span>
                    </div>
                  </template>
                  <div class="column is-12">
                    <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
                      <label class="label my-0 mr-3">Activar variables para este producto</label>
                      <template v-if="modeEdit">
                        <span>
                          <input
                            id="switchVariants"
                            type="checkbox"
                            class="switch is-rounded is-small"
                            :checked="productVariables ? 'checked' : ''"
                            v-model="productVariables"
                            name="switchVariants"
                          >
                          <label for="switchVariants"></label>
                        </span>
                      </template>
                      <template v-else>
                        <p class="paragraph" :class="[ productVariables ? 'text_green' : 'text_error' ]">
                          {{ productVariables ? 'Activo' : 'Inactivo' }}
                        </p>
                      </template>
                    </div>
                  </div>
                  <template v-if="productVariables">
                    <div class="column is-12" v-if="modeEdit">
                      <label class="label">Información para ordenar</label>
                      <p>
                        Ingresa las variaciones de este producto con sus costos correspondientes
                      </p>
                    </div>
                    <div class="column is-12">
                      <div class="buttons">
                        <button v-if="modeEdit" class="button button_micro_dark btn_action_table" @click="activeModalHeaderInformatioToOrder = true">
                          Agregar tabla
                        </button>
                      </div>
                    </div>
                    <div class="column is-12">
                      <span class="help is-danger" v-if="emptyTables">
                        Al activar las variables para este producto debe de haber una tabla y/o archivo PDF
                      </span>
                      <span class="help is-danger" v-if="emptyTablesContent">
                        La(s) tabla(s) esta(n) vacia(s)
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="column is-3">
                      <label class="label">Catálogo</label>
                      <template v-if="modeEdit">
                        <input
                          class="input"
                          type="text"
                          placeholder="Catálogo"
                          v-model="catalogName"
                          name="catalogName"
                          v-validate="'required'"
                          data-vv-as="del catálogo"
                        >
                        <span v-show="vverrors.has('catalogName')" class="help is-danger">{{ vverrors.first('catalogName') }}</span>
                      </template>
                      <template v-else>
                        <p class="paragraph">{{ catalogName }}</p>
                      </template>
                    </div>
                    <div class="column is-3">
                      <label class="label">Clave MI</label>
                      <template v-if="modeEdit">
                        <input
                          class="input"
                          type="text"
                          placeholder="Clave MI"
                          v-model="keyMI"
                          name="keyMI"
                        >
                        <span v-show="vverrors.has('keyMI')" class="help is-danger">{{ vverrors.first('keyMI') }}</span>
                      </template>
                      <template v-else>
                        <p class="paragraph">{{ keyMI || 'Sin información' }}</p>
                      </template>
                    </div>
                    <div class="column is-3">
                      <label class="label">Moneda</label>
                      <template v-if="modeEdit">
                        <div class="select slct is-fullwidth">
                          <select v-model="currencyId" name="currencyId">
                            <option selected disabled :value="null">Moneda</option>
                            <option v-for="currency in currencyList" :key="currency.id" :value="currency.id">
                              {{ currency.code }}
                            </option>
                          </select>
                        </div>
                      </template>
                      <template v-else>
                        <p class="paragraph">{{ currencyName || 'Sin información' }}</p>
                      </template>
                    </div>
                    <div class="column is-3">
                      <label class="label">Costo</label>
                      <template v-if="modeEdit">
                        <input
                          class="input"
                          placeholder="Costo"
                          type="text"
                          name="cost"
                          v-model="cost"
                          v-validate="'decimal'"
                          data-vv-as="del costo"
                          @keypress="onlyNumbersWithDecimal"
                        >
                        <span v-show="vverrors.has('cost')" class="help is-danger">{{ vverrors.first('cost') }}</span>
                      </template>
                      <template v-else>
                        <p class="paragraph">${{ numeral(cost).format('0,0.00') }}</p>
                      </template>
                    </div>
                    <div class="column is-3">
                      <label class="label">Descuento de promoción</label>
                      <template v-if="modeEdit">
                        <input
                          class="input"
                          placeholder="Descuento de promoción"
                          type="text"
                          min="0"
                          max="99"
                          name="promotionDiscount"
                          v-model="promotionDiscount"
                          v-validate="'max:2'"
                          data-vv-as="del descuento"
                          maxlength="2"
                          @keypress="onlyNumbers"
                          autocomplete="off"
                          v-mask="'##'"
                        >
                        <span v-show="vverrors.has('promotionDiscount')" class="help is-danger">{{ vverrors.first('promotionDiscount') }}</span>
                      </template>
                      <template v-else>
                        <p class="paragraph">{{ promotionDiscount || 'Sin información' }}</p>
                      </template>
                    </div>
                    <div class="column is-3">
                      <label class="label">Piezas disponibles</label>
                      <template v-if="modeEdit">
                        <input
                          class="input"
                          placeholder="Piezas disponibles"
                          type="text"
                          name="availableProducts"
                          v-model="availableProducts"
                          @keypress="onlyNumbers"
                        >
                      </template>
                      <p class="paragraph" v-else>
                        {{ availableProducts || 'Sin información' }}
                      </p>
                    </div>
                  </template>
                  <!-- Tablas -->
                  <template v-if="productVariableTables.length > isEmpty && productVariables">
                    <div class="column is-12" v-for="(tableData, index) in productVariableTables" :key="`variable_${tableData.idTable}`">
                      <div class="columns is-multiline" v-if="tableData.active">
                        <div class="column is-12">
                          <div class="columns is-multiline is-mobile">
                            <div class="column is-10-desktop is-10-tablet is-7-mobile" :class="{ 'is-12' : !modeEdit }">
                              <p class="line_right">
                                <span>
                                  {{ tableData.tableName }}
                                </span>
                              </p>
                            </div>
                            <div v-if="modeEdit" class="column is-2-desktop is-2-tablet is-5-mobile is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
                              <img
                                class="icon_delete"
                                src="@/assets/icon/trash_icon.png"
                                alt="Borrar"
                                @click="removeTable(index)"
                              >
                              <img
                                class="icon_edit"
                                src="@/assets/icon/edit_icon_pen.png"
                                alt="Editar"
                                @click="editHeaderTable(tableData)"
                              >
                              <img
                                class="icon_add"
                                src="@/assets/icon/add_button_icon.png"
                                alt="Agregar"
                                @click="activeModaladdRowTable(tableData)"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="column is-12 is_grid">
                          <div class="table-container">
                            <table class="table is-fullwidth is-bordered table_products_var mb-2">
                              <thead>
                                <tr>
                                  <th>
                                    NOMBRE DEL PRODUCTO
                                  </th>
                                  <th>
                                    DESCRIPCIÓN
                                  </th>
                                  <th>
                                    CATÁLOGO
                                  </th>
                                  <th>
                                    DISPONIBILIDAD
                                  </th>
                                  <th>
                                    CLAVE MI
                                  </th>
                                  <template v-for="(optionalHeader, indexHeader) in tableData.header">
                                    <th :key="`header_pro_${indexHeader}`" v-if="optionalHeader.active">
                                      {{ optionalHeader.name }}
                                    </th>
                                  </template>
                                  <th v-if="modeEdit"></th>
                                </tr>
                              </thead>
                              <tbody v-if="tableData.dataTable.length > isEmpty">
                                <template v-for="(rowData, indexRowData) in tableData.dataTable">
                                  <tr :key="indexRowData" v-if="rowData.active">
                                    <td>
                                      {{ rowData.nameProduct }}
                                    </td>
                                    <td>
                                      {{ rowData.description }}
                                    </td>
                                    <td>
                                      {{ rowData.catalogue }}
                                    </td>
                                    <td>
                                      {{ rowData.inventory }}
                                    </td>
                                    <td>
                                      {{ rowData.keyMI || 'N/A' }}
                                    </td>
                                    <td v-for="(optionalRow, optionalRowIndex) in rowData.optionalRow" :key="optionalRowIndex">
                                    <template v-if="optionalRow.name !== 'MONEDA' && optionalRow.name !== 'COSTO'">
                                      {{ optionalRow.value }}
                                    </template>
                                    <template v-else-if="optionalRow.name === 'COSTO'">
                                      {{ numeral(optionalRow.value).format('0,0.00') }}
                                    </template>
                                    <template v-else>
                                      {{ getCurrencyName(optionalRow.value) }}
                                    </template>
                                  </td>
                                    <td v-if="modeEdit" style="min-width: 100px; text-align: center;">
                                      <img
                                        class="icon_delete mx-1"
                                        src="@/assets/icon/trash_icon.png"
                                        alt="Borrar"
                                        @click="removeRowTable(index, indexRowData)"
                                      >
                                      <img
                                        class="icon_edit mx-1"
                                        src="@/assets/icon/edit_icon_pen.png"
                                        alt="Editar"
                                        @click="activeModalRowTable(tableData.idTable, indexRowData, rowData)"
                                      >
                                    </td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="column is-12">
                          <p>
                            {{ tableData.additionalInformation }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import productVariablesTable from '@/constant/productVariablesTable'
import utilities from '@/extras/utilities'
import numeral from 'numeral'
import { mask } from 'vue-the-mask'

export default {
  name: 'ProductMainDetail',
  components: {
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue'),
    ModalHeaderInformatioToOrder: () => import('@/components/Products/ModalHeaderInformatioToOrder.vue'),
    ModalRowInformationToOrder: () => import('@/components/Products/ModalRowInformationToOrder.vue'),
    CardRelatedProducts: () => import('@/components/SelectionGuide/Detail/CardRelatedProducts.vue')
  },
  directives: {
    mask
  },
  computed: {
    ...mapGetters(['productsListSimplified'])
  },
  data () {
    return {
      showSublines: null,
      productVariablesTable: productVariablesTable,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      isEmpty: 0,
      productName: null,
      brandId: null,
      brandName: null,
      catalogName: null,
      keyMI: null,
      status: false,
      introduction: null,
      characterCount: 0,
      description: null,
      deliveryTime: null,
      fixed_VAT: 16,
      availableOnlyMexico: false,
      switchEnableAddCart: false,
      lineId: null,
      lineList: [],
      optionsLines: [],
      sectorIdSelect: null,
      sectorList: [],
      sectorListSelect: [],
      keywordsList: '',
      splitKeywords: [],
      moreSpecs: null,
      productVariables: false,
      productDemo: false,
      currencyId: null,
      currencyName: null,
      cost: null,
      promotionDiscount: null,
      availableProducts: 16,
      brandList: [],
      currencyList: [],
      activeModalHeaderInformatioToOrder: false,
      lastVariableTablesId: 0,
      productVariableTables: [],
      dataCurrentHeaderUpdate: null,
      tableIdCurrentUpdte: null,
      activeModalEditHeaderInformatioToOrder: false,
      activeModalRowInformationToOrder: false,
      currentRowIdTable: null,
      enabledRowHeaders: [],
      activeModalEditRowInformationToOrder: false,
      indexRowTable: null,
      rowHeadersCurrent: {},
      previewImageDataSheet: null,
      pdfFileDataSheet: null,
      previewImageMain: null,
      imageFileMain: null,
      previewImageExtraOne: null,
      imageFileExtraOne: null,
      previewImageExtraTwo: null,
      imageFileExtraTwo: null,
      previewProductVariation: null,
      pdfFileroductVariation: null,
      nameProductVariation: null,
      errorNoLineSelected: false,
      errorNoSubLineSelected: false,
      errorNoChildSubLineSelected: false,
      loadingCheckBox: false,
      sectorListItsEmpty: false,
      emptyTables: false,
      emptyTablesContent: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        ['clean'] // remove formatting button
      ]
    }
  },
  props: {
    loadingCatalog: {
      type: Boolean,
      dafault: false
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    productDetail: {
      type: Object,
      default: () => {}
    },
    brandListParent: {
      type: Array,
      default: () => []
    },
    sectorsListParent: {
      type: Array,
      default: () => []
    },
    linesListParent: {
      type: Array,
      default: () => []
    },
    currencyListParent: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'introduction' (data) {
      if (data) { this.$validator.reset() }
    }
  },
  methods: {
    ...mapActions(['getProductsListSimplified']),
    numeral,
    async spreadData () {
      this.productName = this.productDetail.product_name
      this.brandId = this.productDetail.brandId
      this.brandName = this.productDetail.brand_name
      this.catalogName = this.productDetail.catalogue
      this.keyMI = this.productDetail.key_mi || ''
      this.status = this.productDetail.active
      this.introduction = this.productDetail.introduction
      this.description = this.productDetail.description
      this.previewImageDataSheet = this.productDetail.image_data_sheet ? `${this.ASSETS_URL}${this.productDetail.image_data_sheet}` : null
      this.filename = utilities.objectValidate(this.productDetail, 'image_data_sheet', '')
      this.deliveryTime = this.productDetail.delivery_time
      this.availableOnlyMexico = this.productDetail.available_only_mexico
      this.switchEnableAddCart = this.productDetail.enable_add_cart
      this.optionsLines = this.productDetail.lines
      this.sectorListSelect = this.productDetail.sectors
      // Obtenemos el ultimo id
      // this.sectorIdTemp = await this.productDetail.sectors > this.isEmpty ? this.getLastId(this.productDetail.sectors) : 0
      this.keywordsList = utilities.objectValidate(this.productDetail, 'keywords', '')
      this.splitKeywords = this.keywordsList.split(',').map(word => word.trim())
      // Obtenemos el ultimo id
      this.previewImageMain = this.productDetail.image_main ? `${this.ASSETS_URL}${this.productDetail.image_main}` : null
      this.previewImageExtraOne = this.productDetail.image_one ? `${this.ASSETS_URL}${this.productDetail.image_one}` : null
      this.previewImageExtraTwo = this.productDetail.image_two ? `${this.ASSETS_URL}${this.productDetail.image_two}` : null
      this.moreSpecs = this.productDetail.more_specs
      this.productVariables = this.productDetail.product_variables
      this.currencyId = this.productDetail.currency_id
      this.currencyName = this.productDetail.currency_name
      this.cost = this.productDetail.cost
      this.promotionDiscount = this.productDetail.promotion_discount
      this.productDemo = this.productDetail.request_demo
      this.availableProducts = numeral(this.productDetail.available_products).value()
      this.pdfFileroductVariation = this.productDetail.pdf_product_variation ? `${this.ASSETS_URL}${this.productDetail.pdf_product_variation}` : null
      this.nameProductVariation = this.productDetail.name_product_variation
      this.productVariableTables = this.productDetail.tables
      // Obtenemos el ultimo id
      this.lastVariableTablesId = this.productDetail.tables.length > this.isEmpty ? await this.getLastTableId(this.productDetail.tables) : 0
    },
    async getLastId (myArray) {
      const maxId = await myArray.reduce((prev, current) => {
        if (current.id > prev.id) { return current } else { return prev }
      })
      return maxId.id
    },
    async getLastTableId (myArray) {
      const maxId = await myArray.reduce((prev, current) => {
        if (current.idTable > prev.idTable) { return current } else { return prev }
      })
      return maxId.idTable
    },
    // Limite de CARACTERES
    handleMaxInputLimit (limit) {
      const content = this.introduction

      // Clean input
      const contentWithoutTags = content.replace(/<[^>]+>/g, '')

      // Set cleaned input length
      this.characterCount = contentWithoutTags.length

      if (this.characterCount <= limit) {
        this.introduction = content // Set validated content
      }
    },
    // Imagen Ficha técnica
    async setFileDataSheet (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          this.previewImageDataSheet = true
          this.pdfFileDataSheet = { key: event.target.name, file }
          this.filename = utilities.objectValidate(event.target.files[0], 'name', '')
        }
      }
    },
    // Imagen principal
    async setFileMain (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageMain = e.target.result
          }
          this.imageFileMain = { key: event.target.name, file }
        }
      }
    },
    // Imagen extra
    async setFileExtraOne (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageExtraOne = e.target.result
          }
          this.imageFileExtraOne = { key: event.target.name, file }
        }
      }
    },
    // Imagen extra
    async setFileExtraTwo (event) {
      if (event) {
        const [file] = event.target.files
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageExtraTwo = e.target.result
          }
          this.imageFileExtraTwo = { key: event.target.name, file }
        }
      }
    },
    // PDF
    async setFileProductVariation (event) {
      if (event) {
        const [file] = event.target.files
        this.nameProductVariation = file.name
        // Preview
        if (file) {
          this.pdfFileroductVariation = { key: event.target.name, file }
        }
      }
    },
    // Agregar Lineas
    addSubLineSelected () { // HERE
      if (this.lineId) {
        const doesNotExist = -1
        const lineOption = this.lineList.find(line => line.id === this.lineId)
        // Validamos que no exista el objeto
        const lineExist = this.optionsLines.findIndex(line => line.id === lineOption.id)
        const lineIsDisabled = this.optionsLines.find(line => line.id === this.lineId)
        console.log(this.optionsLines)
        if (lineExist === doesNotExist) {
          // Agregamos la propiedad isSelect a la Línea seleccionada
          lineOption.isSelect = true
          // La linea no existe en la BD
          lineOption.isNew = true
          // Agregar el isSelect a cada opcion disponible
          lineOption.subline.map(subline => {
            subline.isSelect = false
            subline.childLine.map(childLine => {
              childLine.isSelect = false
            })
          })
          // Agregamos los objectos con los isSelect a las opciones
          this.optionsLines.push(lineOption)
        } else if (lineIsDisabled && !lineIsDisabled.isSelect) {
          lineIsDisabled.isSelect = true
          lineIsDisabled.subline.forEach(subline => {
            subline.isSelect = false
            subline.childLine.forEach(childLine => {
              childLine.isSelect = false
            })
          })
        }
        this.lineId = null
      }
    },
    deleteLine (indexLine) {
      const { isNew } = this.optionsLines.find((_, indexFind) => indexFind === indexLine)
      if (isNew) {
        this.optionsLines.splice(indexLine, 1)
      } else {
        this.optionsLines[indexLine].isSelect = false
      }
    },
    async changeCheckboxSubLine (indexLine, indexSubline) {
      this.loadingCheckBox = true
      this.optionsLines[indexLine].subline[indexSubline].isSelect = !this.optionsLines[indexLine].subline[indexSubline].isSelect
      this.loadingCheckBox = false
    },
    async setValueChildLine (indexLine, indexSubline, indexChild) {
      this.loadingCheckBox = true
      this.optionsLines[indexLine].subline[indexSubline].childLine[indexChild].isSelect = !this.optionsLines[indexLine].subline[indexSubline].childLine[indexChild].isSelect
      this.loadingCheckBox = false
    },
    // Agregar sector
    addSector () {
      if (this.sectorIdSelect) {
        const sectorOption = this.sectorList.find(sector => sector.id === this.sectorIdSelect)
        // Validamos que no exista el objeto
        const sectorExist = this.sectorListSelect.findIndex(sector => sector.id === sectorOption.id)
        const doesNotExist = -1
        if (sectorExist === doesNotExist) {
          sectorOption.active = true
          this.sectorListSelect.push(sectorOption)
        } else {
          const sectorEnabled = this.sectorListSelect.find(sector => sector.id === this.sectorIdSelect)
          sectorEnabled.active = true
        }
        this.sectorIdSelect = null
      }
    },
    removeSector (indexSector, sectorId) {
      /* eslint-disable camelcase */
      const { relation_id } = this.sectorListSelect.find(item => item.id === sectorId)
      if (!relation_id) {
        this.sectorListSelect.splice(indexSector, 1)
      } else {
        this.sectorListSelect[indexSector].active = false
      }
    },
    onlyNumbersWithDecimal (evt) {
      const validNumber = new RegExp(/^\d*\.?\d*$/)
      evt = (!evt) ? window.event : evt

      if (validNumber.test(evt.key)) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    onlyNumbers (evt) {
      evt = (!evt) ? window.event : evt
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    // Funciones para las tablas
    addHeaderTable (dataTable) {
      const newtable = {
        idTable: this.lastVariableTablesId += 1,
        tableName: dataTable.tableName,
        additionalInformation: dataTable.additionalInformation,
        header: dataTable.optionalColumns,
        dataTable: [],
        isNew: true,
        active: true
      }
      this.productVariableTables.push(newtable)
      // Cerramos el modal
      this.activeModalHeaderInformatioToOrder = false
    },
    closeModalHeaderInformatioToOrder () {
      this.activeModalHeaderInformatioToOrder = false
      this.activeModalEditHeaderInformatioToOrder = false
      this.tableIdCurrentUpdte = null
      this.dataCurrentHeaderUpdate = null
    },
    removeTable (index) {
      this.productVariableTables[index].active = false
    },
    editHeaderTable (dataTable) {
      this.dataCurrentHeaderUpdate = dataTable
      this.tableIdCurrentUpdte = dataTable.idTable
      this.activeModalEditHeaderInformatioToOrder = true
    },
    updateHeaderTable (dataTable) {
      this.productVariableTables.map((itemTable) => {
        if (itemTable.idTable === dataTable.idTable) {
          itemTable.tableName = dataTable.tableName
          itemTable.additionalInformation = dataTable.additionalInformation
          itemTable.header = dataTable.optionalColumns
          // Map de las columnas del header
          dataTable.optionalColumns.map((optionalColumns) => {
            // Buscamos si existe la columna en los datos de la tabla
            const existInRow = itemTable.dataTable.map((dataRow) => dataRow.optionalRow.find(dataRowFind => dataRowFind.id === optionalColumns.id)).filter(y => y !== undefined)
            if (existInRow.length === 0 && optionalColumns.active) {
              itemTable.dataTable.map((dataRow) => {
                let localName = ''
                let value = null
                if (optionalColumns.id === this.productVariablesTable.COST_HEADER_TABLE) {
                  localName = 'COSTO'
                  value = 0
                } else if (optionalColumns.id === this.productVariablesTable.QUANTITY_HEADER_TABLE) {
                  localName = 'CANTIDAD'
                  value = 0
                } else if (optionalColumns.id === this.productVariablesTable.CURRENCY_HEADER_TABLE) {
                  localName = 'MONEDA'
                  value = 1
                } else if (optionalColumns.id === this.productVariablesTable.DISCOUNT_HEADER_TABLE) {
                  localName = 'DESCUENTO'
                  value = 0
                } else {
                  localName = 'DIAMETRO'
                  value = 0
                }
                dataRow.optionalRow.push({
                  id: optionalColumns.id,
                  name: localName,
                  value: value
                })
                // Ordenamos
                dataRow.optionalRow.sort((a, b) => (a.id - b.id))
              })
            } else if (existInRow.length > 0 && !optionalColumns.active) {
              itemTable.dataTable.map((dataRow) => {
                const removeIndex = dataRow.optionalRow.map(item => item.id).indexOf(optionalColumns.id)
                // Removemos
                dataRow.optionalRow.splice(removeIndex, 1)
                // Ordenamos
                dataRow.optionalRow.sort((a, b) => (a.id - b.id))
              })
            } else {
              return true
            }
          })
        }
      })
      this.activeModalEditHeaderInformatioToOrder = false
      this.tableIdCurrentUpdte = null
      this.dataCurrentHeaderUpdate = null
    },
    // Activar modal para agregar fila - Se envia los datos opcionales seleccionados
    activeModaladdRowTable (tableData) {
      tableData.header.map((header) => {
        if (header.active) {
          const objectHeaderInput = {
            id: header.id,
            name: header.name,
            value: null
          }
          this.enabledRowHeaders.push(objectHeaderInput)
        }
      })
      this.currentRowIdTable = tableData.idTable
      this.activeModalRowInformationToOrder = true
    },
    closeModalRowInformationToOrder () {
      this.activeModalRowInformationToOrder = false
      this.activeModalEditRowInformationToOrder = false
      this.currentRowIdTable = null
      this.enabledRowHeaders = []
      this.indexRowTable = null
      this.rowHeadersCurrent = {}
    },
    // Se agrega datos desde el modal a la nueva fila
    addRowTable (dataRow, idTable) {
      dataRow.isNew = true
      dataRow.active = true
      this.productVariableTables.map((item) => {
        if (item.idTable === idTable) {
          item.dataTable.push(dataRow)
        }
      })
      this.currentRowIdTable = null
      this.enabledRowHeaders = []
      this.activeModalRowInformationToOrder = false
    },
    removeRowTable (indexTable, indexRow) {
      const { isNew } = this.productVariableTables[indexTable].dataTable.find((_, indexFind) => indexFind === indexRow)
      if (isNew) {
        this.productVariableTables[indexTable].dataTable.splice(indexRow, 1)
      } else {
        // El valor active no existe en el objecto
        this.productVariableTables[indexTable].dataTable[indexRow].active = false
      }
    },
    activeModalRowTable (idTable, indexRow, { optionalRow, ...rowData }) {
      this.currentRowIdTable = idTable
      this.indexRowTable = indexRow
      this.enabledRowHeaders = optionalRow
      this.rowHeadersCurrent = rowData
      this.activeModalEditRowInformationToOrder = true
    },
    updateRowTable (dataRow, idTable, indexRowTable) {
      this.productVariableTables.map((item) => {
        if (item.idTable === idTable) {
          item.dataTable.map((childData, index) => {
            if (index === indexRowTable) {
              childData.catalogue = dataRow.catalogue
              childData.description = dataRow.description
              childData.inventory = dataRow.inventory
              childData.keyMI = dataRow.keyMI
              childData.nameProduct = dataRow.nameProduct
              childData.optionalRow = dataRow.optionalRow
            }
          })
        }
      })
      this.activeModalEditRowInformationToOrder = false
      this.currentRowIdTable = null
      this.enabledRowHeaders = []
      this.indexRowTable = null
      this.rowHeadersCurrent = {}
    },
    getCurrencyName (value) {
      // Por default - peso mexicano - cuando se agrega a una fila existente
      const defValue = value || 1
      const { code } = this.currencyList.find(e => e.id === defValue)
      return code
    },
    validateLinesNoEpty () {
      this.errorNoLineSelected = this.optionsLines.length === this.isEmpty || this.optionsLines.every((line) => !line.isSelect)
      return !this.errorNoLineSelected
    },
    validateSectorNoEpty () {
      this.sectorListItsEmpty = this.sectorListSelect.length === this.isEmpty
      return !this.sectorListItsEmpty
    },
    validateTablevalidateVariablesProductNoEpty () {
      let isValidate = false
      if (this.productVariables) {
        // No hay documento no hay tabla
        if (this.productVariableTables.length === this.isEmpty) {
          // No hay documento y tampoco tabla
          this.emptyTables = true
          isValidate = false
        } else {
          this.productVariableTables.map((table) => {
            // El contenido de la tabla esta vacia
            if (table.dataTable.length === this.isEmpty) {
              this.emptyTablesContent = true
            } else {
              isValidate = true
            }
          })
        }
      } else {
        isValidate = true
      }
      return isValidate
    },
    async getDataProduct () {
      this.errorNoLineSelected = false
      this.errorNoSubLineSelected = false
      this.errorNoChildSubLineSelected = false
      this.emptyTables = false
      this.emptyTablesContent = false
      const validateLines = await this.validateLinesNoEpty()
      const validateSector = await this.validateSectorNoEpty()
      const validateVariablesProduct = await this.validateTablevalidateVariablesProductNoEpty()
      if (await this.$validator.validateAll() && validateLines && validateSector && validateVariablesProduct) {
        const hasTable = this.productVariableTables.length > this.isEmpty
        const dataCreate = {
          productId: this.productDetail.id,
          productName: this.productName,
          brandId: this.brandId,
          catalogName: this.catalogName,
          keyMI: this.keyMI || '',
          active: this.status,
          introduction: this.introduction,
          description: this.description,
          dataSheetFile: this.pdfFileDataSheet,
          deliveryTime: this.deliveryTime,
          availableOnlyMexico: this.availableOnlyMexico,
          enableAddCart: this.switchEnableAddCart,
          linesSelect: this.optionsLines,
          sectorList: this.sectorListSelect,
          keywordsList: this.keywordsList,
          imageFileMain: this.imageFileMain,
          imageFileExtraOne: this.imageFileExtraOne,
          imageFileExtraTwo: this.imageFileExtraTwo,
          moreSpecs: this.moreSpecs,
          productVariables: this.productVariables,
          productDemo: this.productDemo,
          currencyId: this.currencyId,
          cost: this.cost,
          promotionDiscount: this.promotionDiscount,
          pdfFileroductVariation: this.pdfFileroductVariation,
          productVariableTables: this.productVariableTables,
          availableProducts: this.availableProducts,
          hasTable,
          relatedProducts: this.$refs.cardAddRelatedProductUpRef.relatedProductsLocal.map(e => e.id)
        }
        console.log(dataCreate)
        //
        return dataCreate
      } else {
        return false
      }
    },
    checkLines () {
      for (let i = 0; i < this.optionsLines.length; i++) {
        if (this.optionsLines[i].isSelect) {
          this.showSublines = true
          return
        }
      }
      this.showSublines = false
    }
  },
  beforeMount () {
    this.brandList = this.brandListParent
    this.lineList = this.linesListParent
    this.sectorList = this.sectorsListParent
    this.currencyList = this.currencyListParent
    this.spreadData()
    this.checkLines()
    this.getProductsListSimplified()
    // convert KEYMI to null is string value is null
    if (this.keyMI === 'null') {
      this.keyMI = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
  }
  .card_upload {
    /deep/ .card-header .card-header-title{
      padding: 0 0 10px 0;
    }
    /deep/ .file-cta {
      width: 100%;
    }
    /deep/ .file-label {
      display: table;
      white-space: normal;
      line-height: 1;
    }
  }

  .tag:not(body) {
    align-items: center;
    background-color: transparent;
    border: solid 1px #cbcbcb;
    color: #818181;
  }
  .delete {
    background-color: transparent;
  }
  .delete::before,
  .delete::after {
    background-color: #818181;
  }
  .delete::before {
    width: 80%;
  }
  .delete::after {
    height: 80%;
  }

  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }

  .btn_action_table {
    min-width: 200px;
  }

  .line_right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.17;
    color: #262626;
    span {
      background: #fff;
      margin: 0 15px;
    }
  }
  .line_right:after {
    background: #e8e8e8;
    height: 2px;
    flex: 1;
    content: '';
  }
  .line_right:before {
    background: none;
  }

  .icon_delete,
  .icon_edit,
  .icon_add {
    cursor: pointer;
    width: 22px;
  }
  .icon_delete:hover {
    filter: invert(41%) sepia(55%) saturate(5359%) hue-rotate(327deg) brightness(100%) contrast(102%);
  }
  .icon_add:hover {
    filter: invert(56%) sepia(84%) saturate(407%) hue-rotate(42deg) brightness(98%) contrast(93%);
  }
  .icon_edit:hover {
    filter: invert(45%) sepia(39%) saturate(2266%) hue-rotate(187deg) brightness(102%) contrast(97%);
  }

  .delete_icon {
    cursor: pointer;
  }

  .content-width {
    width: fit-content;
    padding: 0.75rem;
  }
</style>
